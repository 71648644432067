<template>
  <v-card class="mt-5" flat>
    <v-row class="mx-0">
      <v-col class="pa-5">
        <div class="d-flex align-center">
          <span class="font-weight-bold mr-1">Mã KH:</span>
          <span>{{ `KH${saleReceipt.khachhang.id}` }}</span>
          <v-btn color="primary" class="ml-1" icon small>
            <v-icon
              size="18px"
              @click="
                copyToClipboard(
                  `KH${saleReceipt.khachhang.id}`,
                  'modal-sale-receipts'
                )
              "
              >mdi-content-copy</v-icon
            >
          </v-btn>
        </div>
      </v-col>

      <v-col class="pa-5" cols="auto">
        <div class="">
          <span class="font-weight-bold">Tên KH: </span
          >{{ saleReceipt.khachhang.name }}
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="d-flex align-center justify-center">
          <span class="font-weight-bold pr-1">SĐT:</span>
          <template v-if="saleReceipt.khachhang && saleReceipt.khachhang.phone">
            <span>
              {{ saleReceipt.khachhang.phone | VMask("### ### ####") }}
            </span>
            <v-btn color="primary" class="ml-1" icon small>
              <v-icon
                size="18px"
                @click="
                  copyToClipboard(
                    saleReceipt.khachhang.phone,
                    'modal-sale-receipts'
                  )
                "
                >mdi-content-copy</v-icon
              >
            </v-btn>
          </template>
          <span v-else>N/A</span>
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="text-center">
          <span class="font-weight-bold">Công nợ: </span>
          <span>{{
            saleReceipt.khachhang
              ? saleReceipt.khachhang.cong_no
              : 0 | formatCurrency
          }}</span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    saleReceipt: {
      type: Object,
      required: true
    }
  }
};
</script>

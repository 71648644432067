var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-body-1",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"rounded-0 px-3 py-2",attrs:{"headers":_vm.headers,"items":_vm.saleReceipt.participants,"disable-sort":"","hide-default-footer":"","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.name : "N/A")+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInVoiceRoleName(item.role))+" ")]}},{key:"item.docs",fn:function(ref){
var item = ref.item;
return [(item.docs)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.docs.slice(0, 2)),function(image){return _c('div',{key:image,staticClass:"img-wrapper mr-1"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"grey darken-3 rounded",attrs:{"aspect-ratio":1,"src":image,"contain":""}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"white pa-8",staticStyle:{"height":"70vh"}},[_c('v-img',{attrs:{"src":image,"contain":"","height":"100%","max-height":"70vh"}})],1)])],1)}),(item.docs.length > 2)?_c('div',{staticClass:"primary--text"},[_vm._v("+2")]):_vm._e()],2):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
            ? 'green'
            : item.status === 0
            ? 'orange'
            : 'red accent-2',"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã duyệt" : item.status === 0 ? "Chờ tài liệu" : "Bị từ chối")+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-body-1",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"rounded-0 px-3 py-2",attrs:{"headers":_vm.headers,"items":_vm.saleReceipt.return_goods_histories,"disable-sort":"","hide-default-footer":"","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"primary--text",on:{"click":function($event){return _vm.viewDetailReturn(item.id)}}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.goods_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.goods_value))+" ")]}},{key:"item.phitrahang",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.phitrahang))+" ")]}},{key:"item.need_pay_customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.need_pay_customer))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1 ? 'green' : 'red accent-2',"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã trả" : "Bị huỷ")+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.saleReceipt.id)?_c('tp-modal',{attrs:{"max-width":"1240px","name":"modal-sale-receipts","width":"90%"},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{attrs:{"id":"modal-sale-receipt"}},[_c('v-toolbar',{staticClass:"px-5 pb-0 align-start",attrs:{"extension-height":"40px","height":"56x"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{ref:"tabs",attrs:{"height":"40px"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab',{key:item.id,staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)]},proxy:true}],null,true)},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(" Hoá đơn bán #"+_vm._s(_vm.saleReceipt.code)+" ")]),_c('v-chip',{staticClass:"text-body-1 font-weight-bold px-2 py-2 ml-2",attrs:{"color":_vm.saleReceipt.site === _vm.LIST_MODEL_SITES.GOODSPACE.id
            ? 'teal lighten-5 teal--text teal--lighten-1'
            : 'blue lighten-5 blue--text text--darken-4',"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.saleReceipt.site ? _vm.LIST_MODEL_SITES[_vm.saleReceipt.site].text : _vm.LIST_MODEL_SITES.THINKPRO.text)+" ")]),_c('v-spacer'),(
          _vm.isPermissionCancel &&
            ((!props.payload && _vm.saleReceipt.status !== -1) ||
              (props.payload &&
                !props.payload.mode !== 'VIEW' &&
                _vm.saleReceipt.status !== -1))
        )?_c('v-btn',{attrs:{"color":"red lighten-5 red--text rounded-lg text--accent-2 mr-3","depressed":""},on:{"click":function($event){return _vm.cancelSaleReceipt(props.payload.index)}}},[_vm._v(" Hủy ")]):_vm._e(),_c('v-btn',{staticClass:"cyan lighten-5 rounded-lg mr-2",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.goToSalePage}},[_vm._v(" Chỉnh sửa ")]),(_vm.saleReceipt.status !== _vm.SALE_RECEIPT_STATUS.CANCEL.value)?_c('v-btn',{staticClass:"mr-2",attrs:{"to":{
          name: 'print_sale-receipt',
          params: {
            saleReceiptId: _vm.saleReceipt.id
          }
        },"color":"primary","icon":"","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-tabs-items',{staticClass:"modal-body-scroll",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab-item',{key:item.id,staticClass:"tab-item grey lighten-3 pa-5"},[_c(("tab-" + (item.id)),{ref:"tabItems",refInFor:true,tag:"component"})],1)}),1)],1)}}],null,false,815759507)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
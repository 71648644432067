var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-5"},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v("Chi nhánh")]),(_vm.saleReceipt && _vm.saleReceipt.branch && _vm.saleReceipt.branch.name)?_c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.saleReceipt.branch.name)+" ")]):_vm._e()]),_c('v-col',{staticClass:"pa-5"},[_c('div',{staticClass:"d-flex align-center font-weight-bold mb-1"},[_c('span',{staticClass:"mr-1"},[_vm._v("Người chốt đơn")]),(_vm.isPermissionEditClosingUser)?[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16px"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,3284034565),model:{value:(_vm.isShowModalUpdateClosingUser),callback:function ($$v) {_vm.isShowModalUpdateClosingUser=$$v},expression:"isShowModalUpdateClosingUser"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" Cập nhật người chốt đơn ")]),_c('v-card-text',{staticClass:"text-body-1 mt-2"},[_c('v-form',{ref:"form"},[_c('v-autocomplete',{staticClass:"tp-filter-autocomplete",attrs:{"items":_vm.employees,"dense":"","hide-details":"","item-text":"name","item-value":"id","no-data-text":"Không có dữ liệu","outlined":"","placeholder":"Chọn nhân viên"},model:{value:(_vm.saleReceipt.closing_user_id),callback:function ($$v) {_vm.$set(_vm.saleReceipt, "closing_user_id", $$v)},expression:"saleReceipt.closing_user_id"}})],1)],1),_c('v-card-actions',{staticClass:"px-5 pb-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){_vm.isShowModalUpdateClosingUser = false}}},[_vm._v(" Bỏ qua ")]),_c('v-btn',{attrs:{"disabled":_vm.saleReceiptStatusRequest.value ===
                      'loading-updateSaleReceiptClosingUser',"loading":_vm.saleReceiptStatusRequest.value ===
                      'loading-updateSaleReceiptClosingUser',"color":"primary","text":""},on:{"click":function($event){_vm.saleReceiptStatusRequest.value ===
                    'loading-updateSaleReceiptClosingUser'
                      ? null
                      : _vm.updateSaleReceiptClosingUser()}}},[_vm._v(" Lưu ")])],1)],1)],1)]:_vm._e()],2),_c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.saleReceipt.closing_user && _vm.saleReceipt.closing_user.name ? _vm.saleReceipt.closing_user.name : "N/A")+" ")])]),_c('v-col',{staticClass:"pa-5"},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v("Ngày tạo")]),(_vm.saleReceipt && _vm.saleReceipt.created_at)?_c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.saleReceipt.created_at))+" ")]):_vm._e()]),_c('v-col',{staticClass:"pa-5"},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v("Người tạo")]),(_vm.saleReceipt && _vm.saleReceipt.created_user)?_c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.saleReceipt.created_user.name)+" ")]):_vm._e()]),_c('v-col',{staticClass:"pa-5"},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v("Trạng thái")]),(_vm.saleReceipt && _vm.saleReceipt.status)?_c('div',[_c('v-chip',{staticClass:"text-body-2 font-weight-bold px-2",attrs:{"color":_vm.saleReceipt.status === 1 ? 'green' : 'red accent-2',"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.saleReceipt.status === 1 ? "Thành công" : "Đã hủy")+" ")])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="text-body-1" flat>
    <v-data-table
      :headers="headers"
      :items="saleReceipt.participants"
      class="rounded-0 px-3 py-2"
      disable-sort
      hide-default-footer
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
    >
      <template #[`item.user`]="{ item }">
        {{ item.user ? item.user.name : "N/A" }}
      </template>

      <template #[`item.role`]="{ item }">
        {{ getInVoiceRoleName(item.role) }}
      </template>

      <template #[`item.docs`]="{ item }">
        <div v-if="item.docs" class="d-flex align-center">
          <div
            v-for="image in item.docs.slice(0, 2)"
            :key="image"
            class="img-wrapper mr-1"
          >
            <v-dialog max-width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :aspect-ratio="1"
                  :src="image"
                  class="grey darken-3 rounded"
                  contain
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <div class="white pa-8" style="height: 70vh">
                <v-img
                  :src="image"
                  contain
                  height="100%"
                  max-height="70vh"
                ></v-img>
              </div>
            </v-dialog>
          </div>
          <div v-if="item.docs.length > 2" class="primary--text">+2</div>
        </div>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.status`]="{ item }" class="text-center">
        <v-chip
          :color="
            item.status === 1
              ? 'green'
              : item.status === 0
              ? 'orange'
              : 'red accent-2'
          "
          class="font-weight-bold"
          outlined
          small
        >
          {{
            item.status === 1
              ? "Đã duyệt"
              : item.status === 0
              ? "Chờ tài liệu"
              : "Bị từ chối"
          }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        {
          text: "Người thực hiện",
          value: "user"
        },
        {
          text: "Nghiệp vụ",
          value: "role"
        },
        {
          text: "Giá trị",
          value: "value"
        },
        {
          text: "Ghi chú",
          value: "note"
        },
        {
          text: "Tài liệu",
          value: "docs"
        },
        {
          text: "Trạng thái",
          value: "status"
        },
        {
          text: "",
          align: "right",
          value: "actions"
        }
      ]
    };
  },

  computed: {
    invoiceRoles() {
      return this.$store.getters["ROLE/rolesName"];
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  },

  methods: {
    getInVoiceRoleName(code) {
      const res = this.invoiceRoles.find(item => item.value === code);

      return res.text || "N/A";
    }
  }
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  cursor: pointer;
  height: 32px;
  width: 32px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"datatable text-body-1 px-3 py-2",attrs:{"headers":_vm.headers,"items":_vm.saleReceiptPaymentTransactions,"loading":_vm.saleReceiptStatusRequest.value === 'getSaleReceiptPaymentTransactions',"calculate-widths":"","disable-pagination":"","hide-default-footer":"","item-key":"id","loading-text":"Đang tải dữ liệu","no-data-text":"Không có phiếu thu nào","no-results-text":"Không tìm thấy kết quả phù hợp"},scopedSlots:_vm._u([{key:"header.amount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"w-max-content text-center"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('div',[_vm._v("(Không tính phí TT)")])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:item.id,class:{ 'grey lighten-5': index % 2 === 0 }},[_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at,"HH:mm")))]),_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at,"DD/MM/yyyy")))])]),_c('td',[_c('div',{staticClass:"primary--text tp-btn--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code, 'modal-order')}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.getPaymentTypeText(item.type))+" ")]),_c('td',[(item.receiverable)?_c('span',[_vm._v(_vm._s(item.receiverable.name))]):_c('span',[_vm._v("N/A")])]),_c('td',[_vm._v(_vm._s(_vm.getPaymentKindText(item.kind)))]),_c('td',[(item.receiver_bank)?_c('span',[_vm._v(" "+_vm._s(item.receiver_bank.bank_account_label)+" - "+_vm._s(item.receiver_bank.account_bank)+" ")]):_c('span',[_vm._v("N/A")])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.amount)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.payment_fee)))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"mr-2 font-weight-bold",attrs:{"color":_vm.getReceiptStatus(item.status).color,"outlined":"","small":""}},[(_vm.getReceiptStatus(item.status).isLoading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"color":_vm.getReceiptStatus(item.status).color,"size":15,"width":2,"indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(item.status_label)+" ")],1)],1),_c('td',[_vm._v(_vm._s(item.explain))])])}),0):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v("Không có dữ liệu")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card flat>
    <v-row class="mx-0">
      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Chi nhánh</div>
        <div
          class="py-1"
          v-if="saleReceipt && saleReceipt.branch && saleReceipt.branch.name"
        >
          {{ saleReceipt.branch.name }}
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="d-flex align-center font-weight-bold mb-1">
          <span class="mr-1">Người chốt đơn</span>

          <template v-if="isPermissionEditClosingUser">
            <v-dialog v-model="isShowModalUpdateClosingUser" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" icon x-small v-bind="attrs" v-on="on">
                  <v-icon size="16px">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="font-weight-bold">
                  Cập nhật người chốt đơn
                </v-card-title>

                <v-card-text class="text-body-1 mt-2">
                  <v-form ref="form">
                    <v-autocomplete
                      v-model="saleReceipt.closing_user_id"
                      class="tp-filter-autocomplete"
                      :items="employees"
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      no-data-text="Không có dữ liệu"
                      outlined
                      placeholder="Chọn nhân viên"
                    ></v-autocomplete>
                  </v-form>
                </v-card-text>

                <v-card-actions class="px-5 pb-4 pt-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="isShowModalUpdateClosingUser = false"
                  >
                    Bỏ qua
                  </v-btn>

                  <v-btn
                    :disabled="
                      saleReceiptStatusRequest.value ===
                        'loading-updateSaleReceiptClosingUser'
                    "
                    :loading="
                      saleReceiptStatusRequest.value ===
                        'loading-updateSaleReceiptClosingUser'
                    "
                    color="primary"
                    text
                    @click="
                      saleReceiptStatusRequest.value ===
                      'loading-updateSaleReceiptClosingUser'
                        ? null
                        : updateSaleReceiptClosingUser()
                    "
                  >
                    Lưu
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </div>
        <div class="py-1">
          {{
            saleReceipt.closing_user && saleReceipt.closing_user.name
              ? saleReceipt.closing_user.name
              : "N/A"
          }}
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Ngày tạo</div>
        <div class="py-1" v-if="saleReceipt && saleReceipt.created_at">
          {{ formatDateTime(saleReceipt.created_at) }}
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Người tạo</div>
        <div class="py-1" v-if="saleReceipt && saleReceipt.created_user">
          {{ saleReceipt.created_user.name }}
        </div>
      </v-col>

      <v-col class="pa-5">
        <div class="font-weight-bold mb-1">Trạng thái</div>
        <div v-if="saleReceipt && saleReceipt.status">
          <v-chip
            class="text-body-2 font-weight-bold px-2"
            :color="saleReceipt.status === 1 ? 'green' : 'red accent-2'"
            outlined
            small
          >
            {{ saleReceipt.status === 1 ? "Thành công" : "Đã hủy" }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatDateTime } from "@/core/utils";

export default {
  data() {
    return {
      isShowModalUpdateClosingUser: false
    };
  },

  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    isPermissionEditClosingUser() {
      return (
        this.user.role.toLowerCase() === "admin" ||
        this.user.role.toLowerCase() === "nhóm quyền quản lý cửa hàng" ||
        this.user.role.toLowerCase() === "nhóm quyền giao dịch với khách hàng"
      );
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },

    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  created() {
    if (this.employees.length === 0) {
      this.$store.dispatch("EMPLOYEE/getAllEmployees");
    }
  },

  methods: {
    formatDateTime,

    async updateSaleReceiptClosingUser() {
      await this.$store.dispatch("SALE_RECEIPT/updateSaleReceiptClosingUser", {
        id: this.saleReceipt.id,
        closing_user_id: this.saleReceipt.closing_user_id
      });

      // Alert
      if (
        this.saleReceiptStatusRequest.value ===
        "success-updateSaleReceiptClosingUser"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã cập nhật người chốt đơn"
          }
        });
        this.isShowModalUpdateClosingUser = false;
      } else if (
        this.saleReceiptStatusRequest.value ===
        "error-updateSaleReceiptClosingUser"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">Lỗi cập nhật người chốt đơn</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

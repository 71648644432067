<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="saleReceiptPaymentTransactions"
      :loading="
        saleReceiptStatusRequest.value === 'getSaleReceiptPaymentTransactions'
      "
      calculate-widths
      class="datatable text-body-1 px-3 py-2"
      disable-pagination
      hide-default-footer
      item-key="id"
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có phiếu thu nào"
      no-results-text="Không tìm thấy kết quả phù hợp"
    >
      <template v-slot:header.amount="{ header }">
        <div class="w-max-content text-center">
          <div>{{ header.text }}</div>
          <div>(Không tính phí TT)</div>
        </div>
      </template>
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <!-- Start: Items -->
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            :class="{ 'grey lighten-5': index % 2 === 0 }"
          >
            <td>
              <div>{{ item.created_at | formatTime("HH:mm") }}</div>
              <div>{{ item.created_at | formatTime("DD/MM/yyyy") }}</div>
            </td>

            <td>
              <div class="primary--text tp-btn--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="copied-label"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="copyToClipboard(item.code, 'modal-order')"
                    >
                      {{ item.code }}
                    </div>
                  </template>
                  <span>Sao chép</span>
                </v-tooltip>
              </div>
            </td>
            <td>
              {{ getPaymentTypeText(item.type) }}
            </td>
            <td>
              <span v-if="item.receiverable">{{ item.receiverable.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ getPaymentKindText(item.kind) }}</td>
            <td>
              <span v-if="item.receiver_bank">
                {{ item.receiver_bank.bank_account_label }} -
                {{ item.receiver_bank.account_bank }}
              </span>
              <span v-else>N/A</span>
            </td>

            <td class="text-center">{{ item.amount | formatCurrency }}</td>
            <td class="text-center">{{ item.payment_fee | formatCurrency }}</td>

            <td class="text-center">
              <v-chip
                :color="getReceiptStatus(item.status).color"
                class="mr-2 font-weight-bold"
                outlined
                small
              >
                <v-progress-circular
                  v-if="getReceiptStatus(item.status).isLoading"
                  :color="getReceiptStatus(item.status).color"
                  :size="15"
                  :width="2"
                  class="mr-2"
                  indeterminate
                ></v-progress-circular>

                {{ item.status_label }}
              </v-chip>
            </td>

            <td>{{ item.explain }}</td>
          </tr>
          <!-- End: Items -->
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>
  </v-card>
</template>

<script>
import { getReceiptStatus, LIST_PAYMENT_STATUS } from "@/core/constant";
import { getPaymentKindText, getPaymentTypeText } from "@/core/composables";

export default {
  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at",
          sortable: false
        },
        { text: "Mã phiếu", value: "code", sortable: false },
        { text: "Loại phiếu", value: "type", sortable: false },
        { text: "Đối tượng nhận", value: "receiverable", sortable: false },
        { text: "Phương thức", value: "kind", sortable: false },
        { text: "Tài khoản", value: "receiver_bank", sortable: false },
        { text: "Số tiền", value: "amount", sortable: false },
        { text: "Phí thanh toán", value: "payment_fee", sortable: false },
        {
          text: "Trạng thái",
          align: "center",
          value: "status",
          sortable: false
        },
        {
          text: "Diễn giải",
          value: "status",
          sortable: false
        }
      ],
      LIST_PAYMENT_STATUS
    };
  },

  computed: {
    saleReceiptPaymentTransactions() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptPaymentTransactions"];
    },

    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    }
  },

  methods: {
    getPaymentKindText(kind) {
      return getPaymentKindText(kind);
    },

    getPaymentTypeText(type) {
      return getPaymentTypeText(type);
    },

    getReceiptStatus(status) {
      return getReceiptStatus(status);
    }
  }
};
</script>

<style lang="scss" scoped>
.group-total {
  max-width: 100%;
  width: 310px;

  .text-total {
    font-size: 1.125rem;
  }
}

.w-max-content {
  width: max-content;
}
</style>

import { buildConstants } from "@vt7/utils";

export const SALE_RECEIPT_STATUS = buildConstants(
  {
    CANCEL: {
      value: -1,
      label: "Đã huỷ",
      color: "red accent-2"
    },
    DRAFT: {
      value: 0,
      label: "Nháp",
      color: "orange lighten-2"
    },
    SUCCESS: {
      value: 1,
      label: "Thành công",
      color: "green"
    }
  },
  "value"
);

<template>
  <div id="modal-sale-receipts" class="text-body-1">
    <!-- Start: Cancel reason -->
    <v-card v-if="saleReceipt.status === -1" class="mb-5" flat>
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span class="font-weight-medium">
          Bị hủy bởi
          <span class="font-weight-bold">
            {{
              saleReceipt.canceled_by_user && saleReceipt.canceled_by_user.name
                ? saleReceipt.canceled_by_user.name
                : "N/A"
            }}
          </span>
        </span>
        <span v-if="saleReceipt.canceled_reasons">
          <span class="font-weight-medium">với lý do:</span>
          {{ saleReceipt.canceled_reasons }}
        </span>
      </div>
    </v-card>
    <!-- End: Cancel reason -->

    <card-general />

    <card-customer :sale-receipt="saleReceipt" />

    <card-products-detail></card-products-detail>

    <v-card class="mt-5 px-3 py-2" flat>
      <tab-payment-transitions></tab-payment-transitions>
    </v-card>

    <!-- Start: Note -->
    <div
      v-if="saleReceipt.note"
      class="amber lighten-5 text-body-1 rounded px-3 py-2 mt-5"
    >
      <strong>Ghi chú:</strong> {{ saleReceipt.note }}
    </div>
    <!-- End: Note -->
  </div>
</template>

<script>
import CardCustomer from "./CardCustomer";
import CardGeneral from "./CardGeneral";
import CardProductsDetail from "./CardProductsDetail.vue";
import TabPaymentTransitions from "./TabPaymentTransitions.vue";

export default {
  components: {
    CardCustomer,
    CardGeneral,
    CardProductsDetail,
    TabPaymentTransitions
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  }
};
</script>

<template>
  <v-card class="text-body-1" flat>
    <v-data-table
      :headers="headers"
      :items="saleReceipt.return_goods_histories"
      class="rounded-0 px-3 py-2"
      disable-sort
      hide-default-footer
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
    >
      <template v-slot:[`item.code`]="{ item }">
        <button class="primary--text" @click="viewDetailReturn(item.id)">
          {{ item.code }}
        </button>
      </template>
      <template v-slot:[`item.goods_value`]="{ item }">
        {{ item.goods_value | formatCurrency }}
      </template>
      <template v-slot:[`item.phitrahang`]="{ item }">
        {{ item.phitrahang | formatCurrency }}
      </template>
      <template v-slot:[`item.need_pay_customer`]="{ item }">
        {{ item.need_pay_customer | formatCurrency }}
      </template>
      <template v-slot:[`item.status`]="{ item }" class="text-center">
        <v-chip
          :color="item.status === 1 ? 'green' : 'red accent-2'"
          class="font-weight-bold"
          outlined
          small
        >
          {{ item.status === 1 ? "Đã trả" : "Bị huỷ" }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        {
          text: "Mã phiếu",
          value: "code"
        },
        {
          text: "Chi nhánh",
          value: "branch_name"
        },
        {
          text: "Giá trị trả hàng",
          value: "goods_value"
        },
        {
          text: "Phí trả hàng",
          value: "phitrahang"
        },
        {
          text: "Cần trả khách",
          value: "need_pay_customer"
        },
        {
          text: "Trạng thái",
          value: "status"
        }
      ]
    };
  },

  computed: {
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  },

  methods: {
    async viewDetailReturn(id) {
      await this.$store.dispatch("CUSTOMER_RETURN/getCustomerReturnById", id);

      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnTransactions",
        id
      );

      this.$modal.show({
        name: "modal-customer-return"
      });
    }
  }
};
</script>
